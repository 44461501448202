export default {

    'water': [
        {x: 0.5108855818939197, y: 0.48753407435678736},
        {x: 0.5611504028605978, y: 0.5904736734597789},
        {x: 0.6692541958985219, y: 0.5464058852150199},
        {x: 0.472670546775418, y: 0.5656855425721019},
        {x: 0.4988357960457435, y: 0.5357332177494923},
        {x: 0.5563304885213274, y: 0.4661887394257323},
        {x: 0.6034967931270458, y: 0.46205738427778614},
        {x: 0.4807794100525698, y: 0.3621839839171121},
        {x: 0.7150272469411161, y: 0.3630102549467013},
        {x: 0.7340314806216682, y: 0.46422845607138175},
        {x: 0.6741268309764492, y: 0.7013682415634903},
        {x: 0.4464891623246191, y: 0.6575758769952593},
        {x: 0.26842775544814046, y: 0.39977931576342013}
    ],
    'cloud': [
        {x: 0.301250749593078, y: 0.7197378565921357},
        {x: 0.8569776407093292, y: 0.27756360832690824},
        {x: 0.6279876638396299, y: 0.7447956823438705},
        {x: 0.4352351580570547, y: 0.5389360061680802}
    ]

}