<template>
    <div>
        <div id="map"></div>

        <canvas ref="switch-bg" id="switch-bg" :width="canvas.width" :height="canvas.height"></canvas>
        <div id="switch-box" @click="switchMap">
            <div id="switch-icon" ref="switch-icon" :style="yPosition" v-tooltip.right="{
                content: hint,
                shown: isShow,
                triggers: [],
                distance: 18
              }"></div>
        </div>

        <div id="tools">
            <div class="tool-btn" v-tooltip.left="{
                content: '点击切换显示热点',
                shown: isShow,
                triggers: [],
                distance: 18
              }" @click="showOrHideMarker">
                <img class="marker-btn" v-if="showMarker" src="images/map-marker-alt.png" alt="">
                <img class="marker-btn" v-else src="images/map-marker-off.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>

import OpenSeadragon from 'openseadragon'
import Marker from "@/utils/Marker"

export default {
    name: 'HelloWorld',
    data: () => ({
        viewer: null,
        mapType: 'normal',
        locationPoints: [],
        dragonPoints: [],
        id: null,
        // 动画控制
        canvas:{
            width: 160,
            height: 160,
            k: 54,
            row: 0,
            i: 0,
            context: null,
            img: null
        },
        // 控制提示文本
        isShow: true,
        // 控制显示热点
        showMarker: true,
        // 是否游戏模式
        isGameMode: false
    }),
    mounted() {
        this.init()
        this.id = this.getQueryString('id')
        let mode = this.getQueryString("mode")
        if (mode && mode === "game") {
            this.isGameMode = true
        }

        this.initAnimation()

        // 3秒后摧毁提示文本
        setTimeout(() => {
            this.isShow = false
        }, 3000)
    },
    methods:{
        init(){
            this.viewer = OpenSeadragon({
                id:            "map",
                navigatorSizeRatio: 0.25,
                showNavigationControl: false,
                defaultZoomLevel: 4,
                tileSources:   {
                    height: 7424,
                    width:  7424,
                    tileSize: 256,
                    minLevel: 4,
                    maxLevel: 7,
                    getTileUrl: ( level, x, y ) => {
                        return "images/normal/" + level + "/" + x + "_" + y + ".jpg"
                    }
                },
                gestureSettingsMouse:{
                    clickToZoom: false
                }
            });

            this.viewer.addHandler("canvas-click", (event) =>  {
                const viewerPoint = this.viewer.viewport.pointFromPixel(event.position);
                // console.log("Clicking coordinates:");
                // console.log(viewerPoint)
            });

            this.viewer.addHandler("open", () =>  {



                // 添加水
                for (let i = 0; i < Marker.water.length; i++) {
                    let img = document.createElement("img");
                    img.src = "/images/icon/decorativeMarker-water.gif";

                    this.addMarker(Marker.water[i], img)
                }
                // 添加云
                for (let i = 0; i < Marker.cloud.length; i++) {
                    let img = document.createElement("img");
                    img.src = "/images/icon/decorativeMarker-cloud.gif";
                    img.style.opacity = 0.5

                    this.addMarker(Marker.cloud[i], img)
                }

                this.getLocation()
            })
        },
        addMarker(marker, img){
            let point = new OpenSeadragon.Point(marker.x, marker.y)
            this.viewer.addOverlay({
                element: img,
                location: point,
                placement: OpenSeadragon.Placement.CENTER
            });
        },
        switchMap(){
            this.mapType = this.mapType === 'normal' ? 'dragon' : 'normal'

            this.viewer.open({
                height: 7424,
                width:  7424,
                tileSize: 256,
                minLevel: 4,
                maxLevel: 7,
                getTileUrl: ( level, x, y ) => {
                    if(this.mapType === 'normal'){
                        return "images/normal/" + level + "/" + x + "_" + y + ".jpg"
                    }else{
                        return "images/dragon/" + level + "/" + x + "_" + y + ".jpg"
                    }
                }
            })

            this.getLocation()

        },
        getLocation(){
            const axios = require('axios').default;
            if(this.mapType === 'normal'){
                axios.post('https://dragon.api.lzhailalu.com/api/map/points', {
                    type: 'location',
                    game: this.isGameMode
                }).then( res => {
                    this.locationPoints = res.data.data

                    // 添加到地图
                    for (let i = 0; i < this.locationPoints.length; i++) {
                        let div = this.createPoint(this.locationPoints[i].icon, this.locationPoints[i].location, this.locationPoints[i].location_id)
                        this.addMarker({
                            x: this.locationPoints[i].x,
                            y: this.locationPoints[i].y
                        }, div)
                    }
                })
            }else{
                axios.post('https://dragon.api.lzhailalu.com/api/map/points', {
                    type: 'dragon',
                    game: this.isGameMode
                }).then( res => {
                    this.dragonPoints = res.data.data

                    // 添加到地图
                    for (let i = 0; i < this.dragonPoints.length; i++) {
                        let div = this.createPoint(this.dragonPoints[i].icon, this.dragonPoints[i].location, this.dragonPoints[i].location_id)
                        this.addMarker({
                            x: this.dragonPoints[i].x,
                            y: this.dragonPoints[i].y
                        }, div)
                    }
                })
            }
        },
        createPoint(img, text, id){
            let div = document.createElement('div')
            
            let icon = document.createElement('img')
            icon.src = "/images/icon/" + img + ".png";
            
            div.append(icon)
            
            let hint = document.createElement('span')
            hint.textContent = text
            
            div.append(hint)
            
            div.className = 'point-div animate__animated animate__fadeInDown'

            var tracker = new OpenSeadragon.MouseTracker({
                element: div,
                clickHandler: (event) => {
                    if (event.quick){
                        if (this.id === false){
                            alert('查看地点详情请使用小程序，抖音搜《龙之书》小程序，微信搜索《驯龙宝典》小程序')
                        }else {
                            if (this.mapType === 'normal'){
                                try {
                                    window.tt.miniProgram.navigateTo({
                                        url: "/pages/location/location?id=" + id + "&game=" + this.isGameMode
                                    })
                                } catch (e) {
                                    console.log(e)
                                }
                                try {
                                    window.wx.miniProgram.navigateTo({
                                        url: "/pages/location/location?id=" + id + "&game=" + this.isGameMode
                                    })
                                } catch (e) {
                                    console.log(e)
                                }
                            }else{
                                try {
                                    window.tt.miniProgram.navigateTo({
                                        url: "/pages/category/dragon/dragon?id=" + id + "&game=" + this.isGameMode
                                    })
                                } catch (e) {
                                    console.log(e)
                                }

                                try {
                                    window.wx.miniProgram.navigateTo({
                                        url: "/pages/category/dragon/dragon?id=" + id + "&game=" + this.isGameMode
                                    })
                                } catch (e) {
                                    console.log(e)
                                }

                            }
                        }
                    }


                }
            });

            return div
        },
        getQueryString(variable) {
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i=0;i<vars.length;i++) {
                let pair = vars[i].split("=");
                if(pair[0] === variable){return pair[1];}
            }
            return false;
        },
        initAnimation(){
            let canvas = this.$refs["switch-bg"],
                context = canvas.getContext('2d'),
                img = new Image();
            img.src = "images/icon/dragon-view-glow.png";

            this.canvas.context = context

            img.onload = () => {
                window.requestAnimationFrame(this.drawImg)
            }

            this.canvas.img = img
        },
        drawImg() {
            this.canvas.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

            this.canvas.i++;

            if (this.canvas.i % 6 === 0) {
                this.canvas.row += 1
                this.canvas.i = 0
            }

            this.canvas.context.drawImage(this.canvas.img, this.canvas.i * this.canvas.width, this.canvas.row * this.canvas.height,
                this.canvas.width, this.canvas.height, 0, 0, this.canvas.width, this.canvas.height);

            if (this.canvas.row === 8 && this.canvas.i === 5) {
                this.canvas.i = 0;
                this.canvas.row = 0;
            }

            window.requestAnimationFrame(this.drawImg);
        },
        showOrHideMarker(){
            if (this.showMarker){
                this.viewer.clearOverlays()
            }else{
                this.getLocation()
            }
            this.showMarker = !this.showMarker;
        }
    },
    computed:{
        yPosition(){
            if(this.mapType === 'normal'){
                return 'background-position-y: 0;'
            }else{
                return 'background-position-y: 75px;'
            }
        },
        hint(){
            if(this.mapType === 'normal'){
                return '点击前往龙族地图'
            }else{
                return '点击返回普通地图'
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #map{
        height: 100vh;
        width: 100%;
    }

    #switch-box{
        position: fixed;
        bottom: 20px;
        left: 20px;

        cursor: pointer;
    }

    #switch-bg{
        position: fixed;
        bottom: -23px;
        left: -23px;
    }

    #switch-icon{
        background-image: url("/images/icon/dragon-view-toggle-sprite.jpg");
        width: 75px;
        height: 75px;
        overflow: hidden;
    }

    #tools{
        position: fixed;
        right: 10px;
        top: 10px;
    }

    .marker-btn{
        height: 24px;
        width: 24px;
    }

    .tool-btn{
        background: #fff;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        box-shadow: 1px 1px 11px 4px #e1e1e1;
    }
</style>


<style>
.point-div{
    text-align: center;
    cursor: pointer;
}
.point-div img{
    text-align: center;
    height: 30px;
}
.point-div span{
    display: block;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px black;
    font-size: 12px;
    white-space: nowrap;
}
</style>