<template>
  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
    html, body{
        padding: 0;
        margin: 0;

        background: #fff7d5;
    }
</style>
